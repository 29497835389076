<template>
  <UiHeader
    v-if="props.header"
    header-layout="section-header"
    :header="props.header"
    header-style-class="text-white"
  />
  <div
    v-if="props.boxes?.length && props.boxes.length > 1"
    class="-mx-8 grid grid-cols-1 gap-y-4 md:gap-x-2 lg:mx-0 lg:grid-cols-3 lg:gap-x-4 xl:gap-x-6"
  >
    <UiSimpleBox
      v-for="(box, index) in props.boxes"
      :key="index"
      v-bind="box"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SimpleBox } from '@ui/components/UiSimpleBox/UiSimpleBox.types'
import UiSimpleBox from '@ui/components/UiSimpleBox/UiSimpleBox.vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  header?: string
  boxes?: SimpleBox[]
}>()
</script>
